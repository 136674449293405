.login-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1a1c23 0%, #2d3748 100%);
    padding: 20px;
}

.login-card {
    background: white;
    padding: 2.5rem;
    border-radius: 1rem;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    animation: fadeIn 0.5s ease-out;
}

.login-title {
    color: #2d3748;
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-group label {
    color: #4a5568;
    font-size: 0.875rem;
    font-weight: 500;
}

.form-input {
    padding: 0.75rem 1rem;
    border: 2px solid #e2e8f0;
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: all 0.3s ease;
    background-color: #f8fafc;
}

.form-input:focus {
    outline: none;
    border-color: #3182ce;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

.form-input::placeholder {
    color: #a0aec0;
}

.login-button {
    background-color: #3182ce;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
}

.login-button:hover {
    background-color: #2c5282;
}

.login-button:active {
    transform: translateY(1px);
}

.message {
    margin-top: 1rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    text-align: center;
    font-size: 0.875rem;
    animation: slideIn 0.3s ease-out;
}

.message.error {
    background-color: #fff5f5;
    color: #c53030;
    border: 1px solid #feb2b2;
}

.message.success {
    background-color: #f0fff4;
    color: #2f855a;
    border: 1px solid #9ae6b4;
}

.additional-options {
    display: flex;
    justify-content: space-around;
    margin-top: 1.5rem;
    gap: 1rem;
}

.secondary-button {
    background-color: #edf2f7;
    color: #4a5568;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.secondary-button:hover {
    background-color: #e2e8f0;
    color: #2d3748;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 480px) {
    .login-card {
        padding: 1.5rem;
    }

    .login-title {
        font-size: 1.5rem;
    }

    .additional-options {
        flex-direction: column;
    }

    .secondary-button {
        width: 100%;
    }
}

/* Loading State Styles */
.login-button.loading {
    position: relative;
    color: transparent;
}

.login-button.loading::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

/* Focus styles for accessibility */
.form-input:focus-visible {
    outline: 2px solid #3182ce;
    outline-offset: 2px;
}

.login-button:focus-visible,
.secondary-button:focus-visible {
    outline: 2px solid #3182ce;
    outline-offset: 2px;
}

/* Input error state */
.form-input.error {
    border-color: #fc8181;
}

.form-input.error:focus {
    box-shadow: 0 0 0 3px rgba(252, 129, 129, 0.1);
}

.error-text {
    color: #c53030;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}