.update-content {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.error-message {
    background-color: #fed7d7;
    border: 1px solid #f56565;
    color: #c53030;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.content-type-select {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
}

.content-section {
    margin-bottom: 20px;
}

.content-textarea {
    width: 100%;
    min-height: 150px;
    padding: 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    resize: vertical;
}

.url-section {
    margin-bottom: 20px;
}

.url-input-container {
    width: 100%;
}

.url-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
}

.video-section {
    margin-bottom: 20px;
}

.file-input-container {
    margin-bottom: 15px;
}

.file-requirements {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 10px;
}

.file-info {
    font-size: 0.9em;
    color: #2c5282;
    margin-top: 5px;
}

.video-preview {
    margin: 15px 0;
}

.button-container {
    margin-top: 20px;
}

.upload-button, .update-button {
    background-color: #4299e1;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.upload-button:hover, .update-button:hover {
    background-color: #3182ce;
}

.upload-button:disabled, .update-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}