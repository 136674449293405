/* Dashboard Layout */
.dashboard {
    display: flex;
    min-height: 100vh;
    background-color: #f5f7fa;
}

/* Sidebar Styles */
.sidebar {
    width: 250px;
    background-color: #1a1c23;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.nav-link {
    border: 1px solid white;
    display: block;
    padding: 0.75rem 1rem;
    color: #fff;
    text-decoration: none;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
    font-weight: 500;
}

.nav-link:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateX(5px);
}

.nav-link.active {
    background-color: #3182ce;
}

/* Content Area */
.content {
    flex: 1;
    padding: 2rem;
    overflow-y: auto;
}

/* Burger Menu */
.burger-menu {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
}

.burger-menu button {
    background: #1a1c23;
    color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.burger-menu button:hover {
    background-color: #2d3748;
}

.dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    min-width: 200px;
    margin-top: 0.5rem;
}

.dropdown-menu button {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    text-align: left;
    background: none;
    border: none;
    color: #1a1c23;
    font-size: 0.875rem;
    cursor: pointer;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
}

.dropdown-menu button:hover {
    background-color: #f7fafc;
}

/* Common Form Elements */
textarea {
    width: 100%;
    min-height: 150px;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    resize: vertical;
}

select {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    background-color: white;
}

button {
    background-color: #3182ce;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #2c5282;
}

button:disabled {
    background-color: #cbd5e0;
    cursor: not-allowed;
}

/* File Input Container */
.file-input-container {
    margin-bottom: 1rem;
}

.file-input-container input[type="file"] {
    display: block;
    width: 100%;
    padding: 0.75rem;
    border: 1px dashed #e2e8f0;
    border-radius: 0.5rem;
    background-color: #f8fafc;
}

/* Video Preview */
.video-preview {
    margin: 1rem 0;
    background-color: #000;
    border-radius: 0.5rem;
    overflow: hidden;
}

.video-preview video {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
}

/* URL Input */
.url-input-container {
    margin-bottom: 1rem;
}

.url-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    font-size: 0.875rem;
}

/* Status Message */
.status-message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #edf2f7;
    color: #2d3748;
}

/* Component Specific Styles */
.send-message, .update-content {
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.send-message h2, .update-content h2 {
    color: #2d3748;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
}

/* Button Container */
.button-container {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .dashboard {
        flex-direction: column;
    }

    .sidebar {
        width: 100%;
        padding: 1rem;
    }

    .content {
        padding: 1rem;
    }

    .button-container {
        flex-direction: column;
    }

    .button-container button {
        width: 100%;
    }

    .video-preview video {
        width: 100%;
    }
}

/* Loading State */
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    font-size: 1.25rem;
    color: #2d3748;
}

/* Error States */
.error-message {
    background-color: #fff5f5;
    color: #c53030;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}

/* Success States */
.success-message {
    background-color: #f0fff4;
    color: #2f855a;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}