.send-message {
    position: relative;
    padding: 2rem;
    background: white;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.send-message h2 {
    color: #2d3748;
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.message-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.text-input-container {
    width: 100%;
}

.message-textarea {
    width: 100%;
    min-height: 150px;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    resize: vertical;
    background-color: white;
    transition: border-color 0.3s ease;
}

.message-textarea:focus {
    outline: none;
    border-color: #3182ce;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.1);
}

.file-input-container {
    width: 100%;
}

.file-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px dashed #e2e8f0;
    border-radius: 0.5rem;
    background-color: #f8fafc;
    transition: border-color 0.3s ease;
}

.file-input:hover {
    border-color: #3182ce;
}

.video-preview {
    width: 100%;
    margin: 1rem 0;
    background-color: #000;
    border-radius: 0.5rem;
    overflow: hidden;
}

.video-preview video {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
}

.send-button {
    padding: 0.75rem 1.5rem;
    background-color: #3182ce;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
}

.send-button:hover:not(:disabled) {
    background-color: #2c5282;
}

.send-button:disabled {
    background-color: #cbd5e0;
    cursor: not-allowed;
}

.send-button.loading {
    position: relative;
    color: transparent;
}

.send-button.loading::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
}

.status-message {
    padding: 1rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    margin-top: 1rem;
}

.status-message.error {
    background-color: #fff5f5;
    color: #c53030;
    border: 1px solid #feb2b2;
}

.status-message.success {
    background-color: #f0fff4;
    color: #2f855a;
    border: 1px solid #9ae6b4;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #e2e8f0;
    border-top-color: #3182ce;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
.buttons-section {
    margin: 20px 0;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.button-inputs {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.button-input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex: 1;
}

.add-button, .remove-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.add-button {
    background-color: #4CAF50;
    color: white;
}

.remove-button {
    background-color: #f44336;
    color: white;
}

.add-button:disabled, .remove-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .send-message {
        padding: 1rem;
    }

    .send-message h2 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .message-textarea {
        min-height: 120px;
    }
}

/* Focus styles for accessibility */
.message-textarea:focus-visible,
.file-input:focus-visible,
.send-button:focus-visible {
    outline: 2px solid #3182ce;
    outline-offset: 2px;
}

/* Disabled state styles */
.message-textarea:disabled,
.file-input:disabled {
    background-color: #f7fafc;
    cursor: not-allowed;
}