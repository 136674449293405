/* ConfirmDialog.css */
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.2s ease-out;
}

.dialog-content {
    background: white;
    border-radius: 0.5rem;
    padding: 1.5rem;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.2s ease-out;
}

.dialog-header {
    margin-bottom: 1rem;
}

.dialog-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #2d3748;
    margin: 0;
}

.dialog-body {
    margin-bottom: 1.5rem;
    color: #4a5568;
    font-size: 0.875rem;
    line-height: 1.5;
}

.dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
}

.dialog-button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    border: none;
}

.dialog-button.cancel {
    background-color: #e2e8f0;
    color: #4a5568;
}

.dialog-button.cancel:hover {
    background-color: #cbd5e0;
}

.dialog-button.confirm {
    background-color: #3182ce;
    color: white;
}

.dialog-button.confirm:hover {
    background-color: #2c5282;
}

/* Success Alert */
.success-alert {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background-color: #48bb78;
    color: white;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: slideInRight 0.3s ease-out;
    z-index: 1000;
}

.success-icon {
    font-size: 1.25rem;
}

.success-message {
    font-size: 0.875rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}